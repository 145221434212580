import { createContext, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { getCurrentUser } from "api/auth";

import { Header } from "components/Header";
import { SignIn } from "pages/signIn";
import { SignUp } from "pages/signUp";
import { Setting } from "pages/setting";
import { UserInfo } from "pages/setting/userInfo";
import { Home } from "pages/home";
import { History } from "pages/history";
import { Search } from "pages/search";
import { Achieved } from 'pages/achieved';
import { DamtomoGetDxgScoring } from "pages/damtomoGetDxgScoring";
import { ShukeiRebuildDxgScoring } from "pages/shukeiRebuildDxgScoring";

export const AuthContext = createContext();

function App() {
  const [loading, setLoading] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  const handleGetCurrentUser = async () => {
    try {
      const res = await getCurrentUser();
      if (res?.data.status === 200) {
        setIsSignedIn(true);
        setCurrentUser(res?.data.currentUser);
      } else {
        console.log("no current user");
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetCurrentUser();
  }, [setCurrentUser])

  // 未ログインユーザを弾く
  const Private = ({ children }) => {
    if (!loading) {
      if (isSignedIn) {
        return children;
      } else {
        return <Navigate to="/signin" />;
      }
    } else {
      return <></>;
    }
  };

  // ログイン済みユーザはsignin, signupさせない
  const GuestGuard = ({ children }) => {
    if (!loading) {
      if (isSignedIn) {
        return <Navigate to="/" />;
      }
      return children;
    } else {
      return <></>
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        setLoading,
        isSignedIn,
        setIsSignedIn,
        currentUser,
        setCurrentUser,
      }}
    >
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/signup" element={<GuestGuard><SignUp /></GuestGuard>} /> 
          <Route exact path="/signin" element={<GuestGuard><SignIn /></GuestGuard>} />
          <Route exact path="/" element={<Private><Home /></Private>}/>
          <Route exact path="/setting" element={<Private><Setting /></Private>} />
          <Route exact path="/setting/userInfo" element={<Private><UserInfo /></Private>} />
          <Route exact path="/search" element={<Private><Search /></Private>} />
          <Route exact path="/history" element={<Private><History /></Private>} />
          <Route exact path="/achieved" element={<Private><Achieved /></Private>} />
          <Route exact path="/dxgscoring/get_damtomo" element={<Private><DamtomoGetDxgScoring /></Private>} />
          <Route exact path="/dxgscoring/rebuild_shukei" element={<Private><ShukeiRebuildDxgScoring /></Private>} />
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
