import React, { useContext, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import { signOut } from "api/auth";
import { AuthContext } from "App";
import { QRCodeModal } from './QRCodeModal';
import { ReactComponent as HomeIcon } from '../assets/icons/Home.svg';
import { ReactComponent as ReloadIcon } from '../assets/icons/Reload.svg';
import { ReactComponent as HistoryIcon } from '../assets/icons/History.svg';
import { AchievedIcon } from '../assets/icons/Achieved.js';
import { ReactComponent as SearchIcon } from '../assets/icons/Search.svg';
import { ReactComponent as SettingIcon } from '../assets/icons/Setting.svg';
import { ReactComponent as SignInIcon } from '../assets/icons/SignIn.svg';
import { ReactComponent as SignOutIcon } from '../assets/icons/SignOut.svg';
import { ReactComponent as QRCodeIcon } from '../assets/icons/QRCode.svg';
import styles from './Header.module.css';

export const Header = () => {
  const headerIconSize = "20px";
  const sidebarIconSize = "16px";
  const { loading, isSignedIn, setIsSignedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSignOut = async (e) => {
    try {
      const res = await signOut();
      if (res.data.success === true) {
        // サインアウト時には各Cookieを削除
        Cookies.remove("_access_token");
        Cookies.remove("_client");
        Cookies.remove("_uid");

        setIsSignedIn(false);
        navigate("/signin");

        console.log("Succeeded in sign out");
      } else {
        console.log("Failed in sign out");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // QRCode用
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Header-Sidebar制御用
  const [navActive, setNavActive] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 500);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  if (loading) return 'Loading...';

  return (
    <>
      <nav className={navActive ? 'nav-active' : ''}>
        <div className={styles.title}>精密検索</div>
        {isMobile ? (
          <div className={styles.burger} onClick={toggleNav}>
            &#9776;
          </div>
        ) : (
          <ul className={styles.navLinks}>
            {isSignedIn &&
              <li><Link to="/">
                <HomeIcon width={headerIconSize} height={headerIconSize} />
              </Link></li>}
            {isSignedIn &&
              <li><Link to="/dxgscoring/get_damtomo">
                <ReloadIcon width={headerIconSize} height={headerIconSize} />
                </Link></li>}
            {isSignedIn &&
              <li><Link to="/history">
                <HistoryIcon width={headerIconSize} height={headerIconSize} />
              </Link></li>}
            {isSignedIn &&
              <li><Link to="/achieved">
                <AchievedIcon size={headerIconSize} />
              </Link></li>}
            {isSignedIn &&
              <li><Link to="/search">
                <SearchIcon width={headerIconSize} height={headerIconSize} />
              </Link></li>}
            {isSignedIn &&
              <li><Link to="/setting">
                <SettingIcon width={headerIconSize} height={headerIconSize}/>
              </Link></li>}
            {isSignedIn &&
              <li>
                <button onClick={openModal}>
                  <QRCodeIcon width={headerIconSize} height={headerIconSize} />
                </button>
                <QRCodeModal isOpen={isModalOpen} onClose={closeModal} />
              </li>}
            {isSignedIn && 
              <li><button onClick={handleSignOut}>
                <SignOutIcon width={headerIconSize} height={headerIconSize} />
              </button></li>
            ||
              <li><Link to="/signin">
                <SignInIcon width={headerIconSize} height={headerIconSize} />
              </Link></li>
            }
          </ul>
        )}
        {navActive && isMobile && (
          <div className={styles.burgerNavLinksArea}>
            <ul className={styles.burgerNavLinks}>
              {isSignedIn &&
                <li><Link to="/">
                  <HomeIcon width={sidebarIconSize} height={sidebarIconSize} />Home
                </Link></li>}
              {isSignedIn &&
                <li><Link to="/dxgscoring/get_damtomo">
                  <ReloadIcon width={sidebarIconSize} height={sidebarIconSize} />更新
                  </Link></li>}
              {isSignedIn &&
                <li><Link to="/history">
                  <HistoryIcon width={sidebarIconSize} height={sidebarIconSize} />歌唱履歴
                </Link></li>}
              {isSignedIn &&
                <li><Link to="/search">
                  <SearchIcon width={sidebarIconSize} height={sidebarIconSize} />楽曲検索
                </Link></li>}
              {isSignedIn &&
                <li><Link to="/setting">
                  <SettingIcon width={sidebarIconSize} height={sidebarIconSize}/>設定
                </Link></li>}
              {isSignedIn &&
                <li>
                  <button onClick={openModal}>
                    <QRCodeIcon width={sidebarIconSize} height={sidebarIconSize} />QRコードリーダー
                  </button>
                  <QRCodeModal isOpen={isModalOpen} onClose={closeModal} />
                </li>}
                {isSignedIn && 
              <li><button onClick={handleSignOut}>
                <SignOutIcon width={sidebarIconSize} height={sidebarIconSize} />サインアウト
              </button></li>
            ||
              <li><Link to="/signin">
                <SignInIcon width={sidebarIconSize} height={sidebarIconSize }/>サインイン
              </Link></li>
            }
            </ul>
          </div>
        )}
      </nav>
    </>
  );
};
