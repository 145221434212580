import { gql } from '@apollo/client';

export const DXG_ACHIEVED_SONGS_QUERY = gql`
  query ($per: String!, $page: String!, $achievedType: AchievedType!, $chartTotal: String ) {
    dxgAchievedSongs (per: $per, page: $page, achievedType: $achievedType, chartTotal: $chartTotal){
      list {
        dxgScoring {
          scoringDateTime
          requestNo
          contentsName
          artistName

          totalPoint
          rawPoint

          lastPerformKey

          radarChartTotal
          radarChartPitch
          radarChartStability
          radarChartExpressive
          radarChartVibratoLongtone
          radarChartRhythm
        }
        count
      }
      totalCount
    }
  }
`;
