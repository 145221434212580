import { convertToDateFormat, convertToKeyFormat } from "utils";
import { Tr, Td } from "components/table/Table";

import styles from './Row.module.css';

export const Row = ({
  achievedSong,
  index,
}) => {
  const scoring = achievedSong.dxgScoring
  const scoringCount = achievedSong.count
  return (
    <Tr key={index} >
      <Td className={styles.tableIndex}>
        {index}
      </Td>

      <Td>
        <div className={styles.tableSong}>
          <div className={styles.tableSongNameArea}>
            <div className={styles.tableSongName} data-full-text={scoring['contentsName']}>
              {scoring['contentsName']}
            </div>
          </div>
          <div className={styles.separateLine} />
          <div className={styles.tableArtistNameArea}>
            <div className={styles.tableArtistName} data-full-text={scoring['artistName']}>
              {scoring['artistName']}
            </div>
          </div>
        </div>
      </Td>
      <Td>
        <div className={styles.tableChart}>
          {scoring['radarChartTotal']}
        </div>
      </Td>
      <Td>
        <div className={styles.tableChart}>
          {scoring['radarChartPitch']}
        </div>
      </Td>
      <Td>
        <div className={styles.tableChart}>
          {scoring['radarChartStability']}
        </div>
      </Td>
      <Td>
        <div className={styles.tableChart}>
          {scoring['radarChartExpressive']}
        </div>
      </Td>
      <Td>
        <div className={styles.tableChart}>
          {scoring['radarChartRhythm']}
        </div>
      </Td>
      <Td>
        <div className={styles.tableChart}>
          {scoring['radarChartVibratoLongtone']}
        </div>
      </Td>
      <Td>
        <div className={styles.tableDateCount}>
          <div className={styles.tableDateKeyArea}>
            <div className={styles.tableDateKey}>
              <div className={styles.tableDate}>
                {convertToDateFormat(scoring['scoringDateTime'], '/')}
              </div>
              <div className={styles.tableKey}>
                {convertToKeyFormat(scoring['lastPerformKey'])}
              </div>
            </div>
          </div>
          <div className={styles.tableScoringCount}>
            {scoringCount}回で達成
          </div>
        </div>
      </Td>
    </Tr>
  )
}
