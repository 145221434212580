// TODO: sortに対応する
import React, { useContext, useEffect, useState } from "react"
import Cookies from "js-cookie";
import { useQuery } from '@apollo/client';
import { AuthContext } from "App"
import { range } from 'utils'
import { SelectBox } from "components/SelectBox";
import { Pagination, PerPageSelector } from 'components/Pagination';
import { usePagination } from 'hooks/usePagination';
import { AchievedSongList } from './parts/AchievedSongList';

import { DXG_ACHIEVED_SONGS_QUERY } from './queries';

import styles from './index.module.css';

export const Achieved = () => {
  const { currentUser } = useContext(AuthContext);
  const { currentPage, currentPer, handlePageChange, handlePerChange, resetPagination } = usePagination(1, 100);
  const [ achievedType, setAchievedType ] = useState("chart_total"); 
  const [ chartTotal, setChartTotal ] = useState("495");

  const achievedTypeOptions = {
    total_point: "総合得点（１００点）",
    raw_point: "素点（１００点）",
    chart_total: "チャート合計"
  };

  const chartTotalOptions = Object.fromEntries(
    range(480, 500).map((val) => [val, val])
  );

  useEffect(() => {
    resetPagination();
  }, [achievedType, chartTotal]);

  const handleAchievedTypeChange = (e) => {
    setAchievedType(e.target.value);
  };

  const handleChartTotalChange = (e) => {
    setChartTotal(e.target.value);
  };

  const { loading, error, data } = useQuery(
    DXG_ACHIEVED_SONGS_QUERY,
    {
      variables: {
        page: currentPage.toString(),
        per: currentPer.toString(),
        achievedType: achievedType,
        chartTotal: achievedType === "chart_total" ? chartTotal : null,
      },
      context: {
        headers: {
          "access-token": Cookies.get("_access_token"),
          client: Cookies.get("_client"),
          uid: Cookies.get("_uid"),
        }
      },
      fetchPolicy: "network-only",
    }
  );
  if (loading) return 'Loading...';
  if (error) return `Error: ${error.message}`;

  const achievedSongs = data.dxgAchievedSongs.list;
  const songsCount = data.dxgAchievedSongs.totalCount;
  const totalPages = Math.ceil(songsCount / currentPer);

  return (
    <div className={styles.historyContainer}>
      <h1>
        {achievedTypeOptions[achievedType]}
        {achievedType === "chart_total" ? ` (${chartTotal})` : ""}
        {' '}達成曲リスト
      </h1>
      <div className={styles.description}>{currentUser?.userName} さんの
        {achievedTypeOptions[achievedType]}
        {achievedType === "chart_total" ? ` (${chartTotal})` : ""}
        {' '}達成曲リストページです
      </div>
      <div>
        <label>
          達成した種類：
          <SelectBox 
            value={achievedType} 
            options={achievedTypeOptions} 
            onChange={handleAchievedTypeChange} 
          />
        </label>
      </div>
      {achievedType === "chart_total" && (
        <div>
          <label>
            チャート合計：
            <SelectBox 
              value={chartTotal} 
              options={chartTotalOptions} 
              onChange={handleChartTotalChange} 
            />
          </label>
        </div>
      )}

      <div className={styles.songsCount}>
        {songsCount}件のデータ
      </div>
      
      <div className={styles.paginationContainerHeader}>
        <div className={styles.perPageSelectorContainer}>
          <PerPageSelector
            currentPer={currentPer}
            onPerChange={(newPer) => handlePerChange(newPer)}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        <AchievedSongList
          achievedSongs={achievedSongs}
          currentPage={currentPage}
          currentPer={currentPer}
        />
      </div>

      <div className={styles.paginationContainerFooter}>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(newPage) => handlePageChange(newPage, totalPages)}
        />
      </div>
    </div>
  );
}
