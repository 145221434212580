import React from "react";

import { ThElem } from './ThElem'
import { Table, Thead, Tbody, Tr, Th, Td } from "components/table/Table";

import { Row } from './Row'

export const AchievedSongList = ({
  achievedSongs,
  currentPage,
  currentPer,
}) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th width={'4%'} >
            <ThElem label='No.' />
          </Th>
          <Th width={'26.5%'} >
            <ThElem label="曲名" />
            <ThElem label="歌手名" />
          </Th>
          <Th width={'6.5%'} >
            <ThElem label="チ計" />
          </Th>
          <Th width={'5%'}>
            <ThElem label="音" />
          </Th>
          <Th width={'5%'}>
            <ThElem label="安" />
          </Th>
          <Th width={'5%'}>
            <ThElem label="表" />
          </Th>
          <Th width={'5%'} >
            <ThElem label="リ" />
          </Th>
          <Th width={'5%'} >
            <ThElem label="VL" />
          </Th>
          <Th width={'20%'}>
            <ThElem label="歌唱日 Key" />
            <ThElem label=" 達成回数" />
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {achievedSongs?.map((achievedSong, idx) => {
          const index = (currentPage - 1) * currentPer + idx + 1;
          return (
            <Row key={`achieved-song-row-${index}`} achievedSong={achievedSong} index={index} />
          );
        })}
      </Tbody>
    </Table>
  );
}
