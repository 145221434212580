import styles from "./Table.module.css";

export const Table = ({ children, ...tableProps }) => {
  return (
    <table className={styles.tableStyle} {...tableProps}>
      {children}
    </table>
  )
}

export const Thead = ({ children, ...theadProps }) => {
  return <thead {...theadProps}>{children}</thead>;
};

export const Tbody = ({ children, ...tbodyProps }) => {
  return <tbody {...tbodyProps}>{children}</tbody>;
};

export const Tr = ({ children, ...trProps }) => {
  return (
    <tr className={`${styles.trStyle}`} {...trProps}>
      {children}
    </tr>
  );
};

export const Th = ({ children, width, ...thProps }) => {
  const thWidthStyle = {}
  if (width) {
    thWidthStyle.width = width;
  }
  return (
    <th style={thWidthStyle} className={`${styles.thStyle}`} {...thProps}>
      {children}
    </th>
  );
};

export const Td = ({ children, ...tdProps }) => {
  return (
    <td className={`${styles.tdStyle}`} {...tdProps}>
      {children}
    </td>
  );
};
