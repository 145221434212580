import styles from "./SelectBox.module.css";

export const SelectBox = ({ value, options, onChange, ...SelectBoxProp }) => {
  return (
    <select
      value={value}
      onChange={onChange}
      className={styles.selectBox}
      {...SelectBoxProp}
    >
      {Object.entries(options).map(([val, label]) => (
        <option key={val} value={val}>
          {label}
        </option>
      ))}
    </select>
  );
};
