import styles from './ThElem.module.css';

export const SortableThElem = ({ label, sortBy, currentSortBy, currentSortOrder, handleSortChange }) => {
  const isActive = currentSortBy === sortBy;
  const labelStyle = isActive ? (currentSortOrder === 'ASC' ? styles.asc : styles.desc) : styles.none;

  return (
    <div
      onClick={() => handleSortChange(sortBy)}
      className={`${styles.sortableThElem} ${labelStyle}`}
    >
      {label}
    </div>
  );
};

export const ThElem = ({ label }) => {
  return <div className={styles.thElem}> {label} </div>;
};
